import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { theme } from "../theme"
import Layout from "../components/layout"
import { createGlobalStyle } from "styled-components"
import HomeBg from "../../content/assets/img/home-bg.jpg"
import ParallaxBg from "../../content/assets/img/parallax.jpg"
import FeaturedBlogComponent from "../components/FeatureBlogComponent"

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: MontserratRegular;
  src: url(${require("../../content/assets/fonts/Montserrat-Regular.ttf")});
}
@font-face {
  font-family: MontserratBold;
  src: url(${require("../../content/assets/fonts/Montserrat-SemiBold.ttf")});
}
@font-face {
  font-family: LeagueSpartanBold;
  src: url(${require("../../content/assets/fonts/LeagueSpartan-Bold.otf")});
}
  a{
    text-decoration: none!important;
  }
`

class Blog extends React.Component {
  render() {
    const { data } = this.props

    const siteTitle = data.site.siteMetadata.title

    const renderCarouselContent = () => {
      return (
        <>
          <SearchContainer></SearchContainer>
        </>
      )
    }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <GlobalStyles />

        {renderCarouselContent()}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
          }}
        >
          <Title>¿Quiénes somos?</Title>
          <Paragraph>
            El Circulo Policial del Uruguay es una Institución social sin fines
            de lucro, que asume la representación de sus asociados, policías en
            actividad y retirados, comprometiéndose a salvaguardar, asistir y
            defender los derechos laborales de los mismos, siempre y cuando la
            situación lo amerite.Propende a lograr las mejores condiciones
            laborales, preservando, defendiendo, promocionando e impulsando la
            profesionalización de la carrera policial.
            <br />
            <br />
            El Circulo Policial del Uruguay se mantendrá prescindente y alejado
            de toda ideología, actos o pronunciamiento alguno en materia:
            política partidaria, racial, filosófica o religiosa y no se
            permitirá, que en su Sede o su amparo, se ejecuten hechos que puedan
            alterar esa prescindencia.
          </Paragraph>
        </div>
        <Parallax />
        <div
          className="row-mobile"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
            marginTop: 40,
          }}
        >
          <Box style={{ marginRight: "0.5rem" }}>
            <h3>Misión</h3>
            <p>
              Empeñar los esfuerzos adecuados para generar la integración de
              todo personal policial, a partir de las Instituciones Sociales de
              los Policías, en procura del logro de los fines comunes.
            </p>
          </Box>
          <Box style={{ marginLeft: "0.5rem" }}>
            <h3>Visión</h3>
            <p>
              Crecer socialmente, posicionando a la institución como vanguardia
              en la promoción del desarrollo y bienestar de los socios y la
              Policia en general, desde su espacio social, promoviendo
              actividades académicas, culturales, sociales, deportivas y todas
              aquellas que signifiquen un factor de unión y superación en todos
              los ámbitos que le competen desde su creación.
            </p>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
            backgroundColor: theme.themeColor,
            paddingBottom: 40,
          }}
        >
          <Title style={{ color: "#fff" }}>Quiero ser socio</Title>

          <Link
            style={{
              backgroundColor: "#fff",
              color: theme.themeColor,
              marginTop: 20,
            }}
            href="/socios"
          >
            Asociarme
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingRight: 40,
            paddingLeft: 40,
            marginBottom: 40,
            paddingBottom: 40,
          }}
        >
          <FeaturedBlogComponent data={data} />
          <Link
            style={{
              backgroundColor: "#fff",
              color: theme.themeColor,
              marginTop: 20,
            }}
            href="/noticias"
          >
            Ver Noticias
          </Link>
        </div>
      </Layout>
    )
  }
}

const Title = styled.h1`
  font-family: LeagueSpartanBold;
  color: ${theme.themeColor};
  margin-top: 40px;
  margin-bottom: 20px;

  text-align: center;
`

const Paragraph = styled.p`
  font-family: MontserratRegular !important;
  text-align: center;
`

const Link = styled.a`
  font-family: MontserratRegular !important;
  text-align: center;
  text-decoration: none;
  padding: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border: 1px solid ${theme.themeColor};
  background-color: ${theme.themeColor};
  color: #fff;
  :hover {
    background-color: ${theme.secondaryColor}!important;
    transition: 1s;
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2.25px solid ${theme.themeColor};
  text-align: center;
  width: 50%;
  padding: 2rem;
  height: 15rem;
  :hover {
    background-color: ${theme.secundaryColor};
    transition: 1s;
    h3 {
      color: #fff;
    }
    p {
      color: #fff;
    }
  }
  h3 {
    font-family: LeagueSpartanBold;
    color: ${theme.themeColor};
  }
  p {
    font-family: MontserratRegular !important;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: fit-content;
    margin-bottom: 40px;
    margin-right: 0px;
    margin-left: 0px;
  }
`

const Image = styled.div`
  width: 100%;
  height: 15rem;
  background-image: url(${props => props.src});
  height: 30rem;
  background-position: center center;
  background-size: cover;
  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-right: 0px !important;
  }
`

const Parallax = styled.div`
  background-image: url(${ParallaxBg});
  min-height: 300px;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`

const SearchContainer = styled.div`
  height: 88vh;
  margin: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  background-image: url(${HomeBg});
  background-size: cover;
  background-position: center center;
  img {
    padding-top: 5rem;
  }
  h3 {
    color: #fff;
    font-family: LeagueSpartanBold;
    font-size: 25px;
  }
  @media (max-width: 768px) {
    height: 14rem;
    .row-mobile {
      flex-wrap: wrap;
    }
    background-image: url(${HomeBg});
    h3 {
      font-size: 15px;
      text-align: center;
      padding: 3rem;
    }
    img {
      width: 15rem;
    }
  }
`

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            blogPost
            headerImg
            path
          }
        }
      }
    }
  }
`
