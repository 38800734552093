import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { rhythm } from "../../utils/typography"



const Blog = props => {
  const { data } = props
  const posts = data.allMdx.edges

  return (
      <div style={{ margin: "20px 0 40px", display:'flex', flexDirection:'row', justifyContent:'start', flexWrap: 'wrap', alignItems:'center', paddingRight: 40, paddingLeft: 40 }}>
        {posts.map(({ node }) => {
          console.log(node.frontmatter.reactions)
          const title = node.frontmatter.title || node.fields.slug;
          if(node.frontmatter.blogPost){
          return (
            <PostDiv key={node.fields.slug}>
              <Link
                style={{ boxShadow: `none` }}
                to={`blog/${node.frontmatter.path}`}
              >
                <HeaderPost headerImg={node.frontmatter.headerImg} />
                <PostTile style={{ marginBottom: rhythm(1 / 4) }}>
                  {title}
                </PostTile>
                <small>{node.frontmatter.date}</small>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </Link>
            </PostDiv>
          )}
          return <></>
        })}
      </div>
  )
}

export default Blog

const PostTile = styled.h3`
  margin-bottom: rhythm(1 / 4);
  font-family: MonteserratR;
  color: #f3b52a;
  margin-top: 1.5rem !important;
  margin-left: 1rem;
  a {
    font-family: MonteserratR;
    color: #f3b52a;
  }
`

const PostDiv = styled.div`
  margin-bottom: 2rem;
  margin-right: 1rem;
  width:25rem;
  @media (max-width: 768px) {
    width:20rem;
  }
  small,
  p {
    font-family: MontserratL;
    color: black;
    margin-left: 1rem;
  }
  border: 1px solid #f3b543;
  border-radius: 5px;
`


const HeaderPost = styled.div`
  background-image: url(${props => props.headerImg});
  width: 100%;
  height: 10rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            blogPost
            headerImg
            path
          }
        }
      }
    }
  }
` 